<template>
  <v-form class="user-data-form" v-model="valid" ref="form">
    <v-row no-gutters>
      <!-- EMAIL -->
      <v-col cols="12" :sm="columnsSmallAndUp">
        <label v-html="`${$t('profile.contacts.email')} *`" />
        <v-text-field
          v-model="userData.email"
          :placeholder="`${$t('profile.contacts.email')} *`"
          :rules="emailRules"
          required
          outlined
          dense
          @change="validate"
          :disabled="isSocialLogin || isDisabled"
        ></v-text-field>
      </v-col>
      <!-- HOME PHONE (visibile solo in profile update) -->
      <v-col cols="12" :sm="columnsSmallAndUp" v-if="isProfileForm">
        <label v-html="`${$t('profile.contacts.homePhone')}`" />
        <v-text-field
          :placeholder="`${$t('profile.contacts.homePhone')}`"
          v-model="userData.contact.homePhone"
          outlined
          dense
          @change="validate"
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
      <!-- MOBILE PHONE -->
      <v-col cols="12" :sm="columnsSmallAndUp">
        <label v-html="`${$t('profile.contacts.mobilePhone')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.contacts.mobilePhone')} *`"
          v-model="userData.phone"
          required
          :rules="mobilePhoneRules"
          outlined
          dense
          @change="validate"
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
      <!-- FIRST NAME -->
      <v-col cols="12" :sm="columnsSmallAndUp" v-if="askFirstName">
        <label v-html="`${$t('profile.firstName')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.firstName')} *`"
          v-model="userData.person.firstName"
          required
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
          :disabled="isSocialLogin || isDisabled"
        />
      </v-col>
      <!-- LAST NAME -->
      <v-col cols="12" :sm="columnsSmallAndUp" v-if="askLastName">
        <label v-html="`${$t('profile.lastName')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.lastName')} *`"
          v-model="userData.person.lastName"
          required
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
          :disabled="isSocialLogin || isDisabled"
        />
      </v-col>
      <!-- ITALIAN CITIZEN? -->
      <v-col cols="12" v-if="!isRegisterWithCard && !isProfileForm">
        <label v-html="$t('profile.isItalianCitizen')" />
        <v-row no-gutters>
          <v-radio-group dense v-model="showFiscalCode" mandatory row>
            <v-radio :value="true"
              ><template v-slot:label>
                <span class="">{{ $t("common.yes") }}</span>
              </template></v-radio
            >
            <v-radio :value="false"
              ><template v-slot:label>
                <span class="">{{ $t("common.no") }}</span>
              </template></v-radio
            ></v-radio-group
          >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on">
                $info
              </v-icon>
            </template>
            {{ $t("profile.isFCTooltip") }}
          </v-tooltip></v-row
        >
      </v-col>

      <!-- FISCAL CODE -->
      <v-col cols="12" :sm="columnsSmallAndUp" v-if="showFiscalCode">
        <label v-html="`${$t('profile.fiscalCode')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.fiscalCode')} *`"
          v-model="userData.person.fiscalCode"
          required
          :rules="cfRules"
          outlined
          dense
          @change="validate"
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>

      <!-- necessaria per l'allineamento dei campi in form di registrazione -->
      <v-col cols="0" sm="6" v-if="isRegisterWithCard && !isProfileForm" />

      <!-- TIGROS CARD (solo su form profilo) -->
      <v-col cols="12" :sm="columnsSmallAndUp" v-if="isProfileForm">
        <label v-html="`${$t('profile.card')}`" />
        <v-text-field
          :placeholder="`${$t('profile.cardPlaceholder')}`"
          outlined
          dense
          readonly
          v-model="userData.fidelityCard"
          :disabled="isDisabled"
        />
      </v-col>

      <!-- blocco delle informazioni di nascita solo se utente straniero in fase di registrazione -->
      <!-- eccezione: Birthdate (visibile anche nella Profile Update) -->
      <!-- BIRTHDATE -->
      <v-col
        cols="12"
        :sm="columnsSmallAndUp"
        v-if="birthDataFields || isProfileForm"
      >
        <label v-html="`${$t('profile.birthDate')} *`" />
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birthDate"
              :placeholder="`${$t('profile.birthDate')} *`"
              :rules="[requiredRules()]"
              required
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              :disabled="isDisabled"
            >
              <v-icon slot="append">$editcalendar</v-icon>
            </v-text-field>
          </template>
          <v-date-picker
            locale="it-IT"
            ref="picker"
            :active-picker.sync="activePicker"
            v-model="userData.person.birthDate"
            min="1910-01-01"
            :max="$dayjs().format('YYYY-MM-DD')"
            first-day-of-week="1"
            type="date"
            no-title
            @input="onBirthdateInput"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="birthDataFields"
        cols="12"
        sm="6"
        class="d-flex flex-column flex-sm-row align-start align-sm-center"
      >
        <!-- GENDER -->
        <label v-html="`${$t('profile.gender')} *`" class="mr-0 mr-sm-4" />
        <v-radio-group
          dense
          v-model="userData.person.gender"
          mandatory
          :column="!$vuetify.breakpoint.xs"
          row
          :rules="[requiredRules()]"
          :disabled="isDisabled"
        >
          <v-radio value="M"
            ><template v-slot:label>
              <span>M</span>
            </template></v-radio
          >
          <v-radio value="F"
            ><template v-slot:label>
              <span>F</span>
            </template></v-radio
          ></v-radio-group
        >
      </v-col>

      <!-- BORNCOUNTRY -->
      <v-col cols="12" sm="6" v-if="birthDataFields">
        <label v-html="`${$t('profile.bornCountry')} *`" />
        <v-combobox
          :placeholder="`${$t('profile.bornCountry')} *`"
          v-model="supportData.bornCountry.name"
          :items="countries"
          required
          height="40"
          :rules="requiredCountry"
          outlined
          dense
          return-object
          append-icon=""
          item-text="name"
          item-value="name"
          @change="selectBornCountry"
          :disabled="isDisabled"
        ></v-combobox>
      </v-col>

      <!-- BIRTHPLACE -->
      <v-col cols="12" sm="6" v-if="birthDataFields">
        <label v-html="`${$t('profile.birthPlace')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.birthPlacePlaceholder')} *`"
          v-model="userData.person.birthPlace"
          required
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
          :disabled="isDisabled"
        />
      </v-col>
      <!-- </v-row> -->

      <!-- pulsanti di annullamento e conferma non visibili nel profilo utente -->
      <v-col cols="12" class="mt-6">
        <v-layout
          v-if="showButtons"
          :column="$vuetify.breakpoint.xs"
          :row="$vuetify.breakpoint.smAndUp"
          class="align-sm-center"
        >
          <v-row
            no-gutters
            justify="space-between"
            justify-sm="start"
            class="mt-5 mt-sm-0"
          >
            <v-col cols="5">
              <v-btn
                x-large
                depressed
                block
                class="primary--text"
                v-html="$t('common.back')"
                @click="back"
              />
            </v-col>
            <v-col cols="5">
              <v-btn
                color="primary"
                x-large
                depressed
                block
                v-html="$t('common.next')"
                @click="submit"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
          <label v-html="$t('profile.mandatoryField')" />
        </v-layout>
      </v-col>
    </v-row>
  </v-form>
</template>

<style lang="scss">
.user-data-form {
  .v-autocomplete {
    input,
    ::placeholder {
      font-size: 16px !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    [class*=" col-"] {
      padding: 0 12px !important;
    }
  }

  .w-100 {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      min-width: 75%;
    }
  }
}
</style>

<script>
import TigrosService from "@/service/tigrosCustomService";
import {
  requiredValue,
  isCF,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";
export default {
  name: "UserDataForm",
  props: {
    userData: {
      type: Object,
      required: true
    },
    supportData: {
      type: Object,
      required: true
    },
    isProfileForm: { type: Boolean, default: false },
    registrationType: { type: String, default: "OldUser" },
    isFiscalCode: { type: Boolean, default: true },
    isRegisterWithCard: { type: Boolean, default: false },
    hasFidelityCard: { type: Boolean, default: false },
    askFirstName: { type: Boolean, default: true },
    askFiscalCode: { type: Boolean, default: true },
    askLastName: { type: Boolean, default: true },
    showButtons: { type: Boolean, default: true },
    isSocialLogin: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      menu: false,
      countries: [],
      requiredRules: requiredValue,
      showFiscalCode: true,
      activePicker: null,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ],
      cfRules: [requiredValue(), isCF()],
      requiredCountry: [requiredValue("Selezionare una nazione")],
      valid: false
    };
  },
  computed: {
    // numero di colonne per campo (6 in form di registrazione, 4 in profilo utente)
    columnsSmallAndUp() {
      return this.isProfileForm ? 4 : 6;
    },
    birthDataFields() {
      return this.isRegisterWithCard ? true : !this.showFiscalCode;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    async loadCountriesList() {
      this.countries = await TigrosService.getCountries(-1);
    },
    selectBornCountry(item) {
      this.supportData.bornCountry = item;
      for (var i = 0; i < this.userData.person.personInfos.length; i++) {
        if (this.userData.person.personInfos[i].personInfoTypeId === 14) {
          this.userData.person.personInfos[i].value = item.itemId;
          break;
        }
      }
      this.validate();
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    onBirthdateInput() {
      this.menu = false;
      this.validate();
    },
    back() {
      this.$emit("back");
    },
    submit() {
      if (this.valid) {
        if (
          this.registrationType == "NewUser" &&
          this.showFiscalCode &&
          !this.isRegisterWithCard
        ) {
          this.userData.person.gender = null;
        }
        console.log(this.userData);
        this.$emit("submit", this.userData, this.supportData);
      }
    }
  },
  created() {
    if (
      this.$route.path === "/registration-card" &&
      !this.userData.fidelityCard
    ) {
      this.back();
    } else {
      this.loadCountriesList();
      this.showFiscalCode = this.askFiscalCode;
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
